import { DevicesCard } from './devices-card';
import { LocationsCard } from './locations-card';
import { DistributionCard } from './distribution-card';
import { PagesCard } from './pages-card';
import { AcquisitionCard } from './acquisition-card';
import { TimeSeriesCard } from './time-series-card';
import { KeyMetricsCard } from './key-metrics-card';
import { TrafficSectionNavbar } from './traffic-section-navbar';
import { useEffect, useState } from 'react';
import { Button, Callout } from '@tremor/react';
import { Comparison, DateRange, Filter } from '../../../requests/api/traffic.requests';
import { CenteredSpinner, SkeletonLoader } from '../../../components';
import { useGetWebsites } from '../../../requests/api/website.requests';
import { useQuery } from '@tanstack/react-query';
import { Ga4ConnectorCard } from './ga4-connector-card';
import { getDefaultWebsiteId, saveLastSelectedWebsiteId } from './traffic-section.utils';

function useInitialLoading({
  cardsRequiredToBeLoaded,
}: {
  cardsRequiredToBeLoaded: string[];
}) {
  const [loadedCards, setLoadedCards] = useState<string[]>([]);

  const markCardAsLoaded = (cardName: string) =>
    setLoadedCards(loadedCards => [...loadedCards, cardName]);

  const areAllRequiredCardsLoaded = cardsRequiredToBeLoaded.every(card =>
    loadedCards.includes(card),
  );

  return {
    areAllRequiredCardsLoaded,
    markCardAsLoaded,
  };
}

export default function TrafficSection() {
  /* Websites */
  const getWebsites = useGetWebsites();
  const websites = useQuery({
    queryKey: ['websites'],
    queryFn: () => getWebsites(),
    refetchOnWindowFocus: false,
  });
  const [websiteId, setWebsiteId] = useState<string | undefined>(undefined);

  function onWebsiteIdChange(websiteId: string) {
    saveLastSelectedWebsiteId(websiteId);
    setWebsiteId(websiteId);
  }

  useEffect(() => {
    const defaultWebsiteId = getDefaultWebsiteId(websites.data?.websites);
    setWebsiteId(defaultWebsiteId);
  }, [websites.data]);

  /* Navbar */
  const [dateRange, setDateRange] = useState<DateRange>({
    type: 'shortcut',
    name: 'last7days',
  });
  const [filters, setFilters] = useState<Filter[]>([]);

  const [comparison, setComparison] = useState<Comparison | undefined>(undefined);

  const { areAllRequiredCardsLoaded, markCardAsLoaded } = useInitialLoading({
    cardsRequiredToBeLoaded: ['keyMetrics', 'distribution'],
  });

  /* Cards */
  function onAddFilter(filter: Filter) {
    setFilters(filters => [...filters, filter]);
    setComparison(
      comparison =>
        comparison && {
          ...comparison,
          filters: [...comparison.filters, filter],
        },
    );
  }

  /* Show more section */
  const [isShowMoreSectionVisible, setIsShowMoreSectionVisible] = useState(false);
  const {
    areAllRequiredCardsLoaded: areAllRequiredShowMoreSectionCardsLoaded,
    markCardAsLoaded: markShowMoreSectionCardAsLoaded,
  } = useInitialLoading({
    cardsRequiredToBeLoaded: ['devices', 'locations'],
  });

  if (websites.isPending) return <CenteredSpinner />;

  if (websites.isError)
    return (
      <Callout title="An error has occurred" color="red">
        An error occurred while retrieving the list of your websites. Try refreshing the
        page.
      </Callout>
    );

  if (!websites.data.isUserConnectedToGoogleAnalytics) {
    return <Ga4ConnectorCard />;
  }

  if (websites.data.websites.length === 0)
    return (
      <Callout title="No website found" color="yellow">
        Linito has not found any websites associated with your Google Analytics account.
      </Callout>
    );

  if (!websiteId) return null;

  return (
    <section className="space-y-6">
      <TrafficSectionNavbar
        websites={websites.data.websites}
        websiteId={websiteId}
        dateRange={dateRange}
        filters={filters}
        comparison={comparison}
        onWebsiteIdChange={onWebsiteIdChange}
        onDateRangeChange={setDateRange}
        onFiltersChange={setFilters}
        onComparisonChange={setComparison}
      />
      {!areAllRequiredCardsLoaded && (
        <>
          <div className="flex space-x-6">
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
          </div>
          <div className="flex space-x-6">
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
            <SkeletonLoader className="h-48 flex-1" />
          </div>
        </>
      )}
      <div className={'space-y-6' + (areAllRequiredCardsLoaded ? '' : ' hidden')}>
        <div className="grid grid-cols-7 gap-6">
          <div className="col-span-7 lg:col-span-5">
            <TimeSeriesCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
            />
          </div>
          <div className="col-span-7 lg:col-span-2">
            <KeyMetricsCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
              onInitialLoadingComplete={() => markCardAsLoaded('keyMetrics')}
            />
          </div>
        </div>
        <div className="grid grid-cols-11 gap-6">
          <div className="col-span-11 lg:col-span-3">
            <DistributionCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
              onInitialLoadingComplete={() => markCardAsLoaded('distribution')}
              onAddFilter={onAddFilter}
            />
          </div>
          <div className="col-span-11 lg:col-span-8">
            <AcquisitionCard
              websiteId={websiteId}
              dateRange={dateRange}
              filters={filters}
              comparison={comparison}
              onAddFilter={onAddFilter}
            />
          </div>
        </div>
        <div>
          <PagesCard
            websiteId={websiteId}
            dateRange={dateRange}
            filters={filters}
            comparison={comparison}
            onAddFilter={onAddFilter}
          />
        </div>
        {!isShowMoreSectionVisible ? (
          <div className="flex justify-center">
            <Button variant="light" onClick={() => setIsShowMoreSectionVisible(true)}>
              Show devices and locations
            </Button>
          </div>
        ) : (
          <>
            {!areAllRequiredShowMoreSectionCardsLoaded && (
              <div className="grid grid-cols-2 gap-6">
                <SkeletonLoader className="col-span-2 h-48 lg:col-span-1" />
                <SkeletonLoader className="col-span-2 h-48 lg:col-span-1" />
              </div>
            )}
            <div
              className={
                'grid grid-cols-2 gap-6' +
                (areAllRequiredShowMoreSectionCardsLoaded ? '' : ' hidden')
              }
            >
              <div className="col-span-2 lg:col-span-1">
                <DevicesCard
                  websiteId={websiteId}
                  dateRange={dateRange}
                  filters={filters}
                  onInitialLoadingComplete={() =>
                    markShowMoreSectionCardAsLoaded('devices')
                  }
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <LocationsCard
                  websiteId={websiteId}
                  dateRange={dateRange}
                  filters={filters}
                  onInitialLoadingComplete={() =>
                    markShowMoreSectionCardAsLoaded('locations')
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
