export function formatDate({ date }: { date: string }): string {
  const dateFormatter = new Intl.DateTimeFormat(process.env.REACT_APP_INTL_LANGUAGE, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  // new Date('2024-12-31') -> 2024-12-31T00:00:00.000Z
  // new Date('2024-12-31T00:00:00') -> 2024-12-30T23:00:00.000Z
  return dateFormatter.format(new Date(`${date}T00:00:00`));
}
