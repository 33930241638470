import { Footer } from '../../components';
import { Header } from './header';
import { Navbar } from './navbar';
import { TrafficSection } from './traffic-section';

export default function MainPage() {
  return (
    <div className="mx-auto flex h-full flex-col space-y-6 px-4 pt-16 xl:container md:px-20 lg:px-4 xl:px-20">
      <div className="pb-2">
        <Header />
      </div>
      <div className="rounded-lg bg-blue-700 bg-opacity-10 p-4 text-tremor-default text-blue-700">
        Linito is currently in beta version. Feel free to write to{' '}
        <a href="mailto:loic.poullain@linito.io" className="underline underline-offset-4">
          loic.poullain@linito.io
        </a>{' '}
        if you need anything or have any feedback. 👍
      </div>
      <Navbar />
      <TrafficSection />
      <div className="grow"></div>
      <Footer />
    </div>
  );
}
