import { useQuery } from '@tanstack/react-query';
import { Card, List, ListItem } from '@tremor/react';
import { useParams } from 'react-router-dom';
import { CardTitle } from '../../../main/traffic-section/common';
import { useGetUser } from '../../../../requests/api/back-office.requests';
import { LoadingPage } from '../../../../components';
import { AccountStatus } from '../../common';

export default function BackOfficeUserDetailsPage() {
  const { userId } = useParams();

  const getUser = useGetUser();

  const user = useQuery({
    queryKey: ['getBackOfficeUser'],
    queryFn: () => getUser(parseInt(userId!)),
  });

  if (!user.isSuccess) {
    return <LoadingPage hasError={user.isError} />;
  }

  return (
    <div className="container mx-auto space-y-6 px-20 pt-16">
      <h1 className="pb-4 text-3xl">
        {user.data.firstName} {user.data.lastName}
      </h1>
      <div className="grid grid-cols-2">
        <Card className="col-span-1">
          <CardTitle title="Details" />
          <List className="mt-2">
            <ListItem>
              <span>User ID</span>
              <span>{user.data.id}</span>
            </ListItem>
            <ListItem>
              <span>Email</span>
              <span>{user.data.email}</span>
            </ListItem>
            <ListItem>
              <span>First name</span>
              <span>{user.data.firstName}</span>
            </ListItem>
            <ListItem>
              <span>Last name</span>
              <span>{user.data.lastName}</span>
            </ListItem>
            <ListItem>
              <span>Account status</span>
              <AccountStatus status={user.data.status} />
            </ListItem>
          </List>
        </Card>
      </div>
    </div>
  );
}
