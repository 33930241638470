import { AggregationStep } from '../../../../requests/api/traffic.requests';
import { DateFormat, formatDateTime } from '../../../../utils';

export function formatTimeSeries({
  timeSeries,
  label,
  comparisonLabel,
  aggregationStep,
}: {
  timeSeries: {
    dateTime: string;
    data: {
      value: number | null;
      comparisonValue?: number | null;
    };
  }[];
  label: string;
  comparisonLabel: string;
  aggregationStep: AggregationStep;
}) {
  const formats: Record<AggregationStep, DateFormat> = {
    hour: 'hours-and-minutes',
    day: 'day-and-month',
    month: 'month-and-year',
  };

  return timeSeries
    .sort((a, b) => new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime())
    .map(({ dateTime, data }) => ({
      formattedDate: formatDateTime({ dateTime, format: formats[aggregationStep] }),
      [label]: data.value,
      [comparisonLabel]: data.comparisonValue,
    }));
}
