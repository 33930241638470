export default function ForbiddenPage() {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="flex flex-col gap-6 text-center">
        <p className="text-base font-semibold text-indigo-600">403</p>
        <h1 className="mb-3.5 text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Forbidden
        </h1>
        <p className="text-base leading-7 text-gray-600">
          You do not have the right permissions to access this page.
        </p>
      </div>
    </main>
  );
}
