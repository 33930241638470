import { useMakeAuthenticatedGetRequest } from '../requests.hooks';

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  status: 'signed-up' | 'ga4-connected';
  permissions: string[];
}

export function useListAllUsers(): () => Promise<User[]> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/back-office/users');
    return data;
  };
}

export function useGetUser(): (userId: number) => Promise<User> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async userId => {
    const { data } = await makeAuthenticatedGetRequest(
      `/api/back-office/users/${userId}`,
    );
    return data;
  };
}
