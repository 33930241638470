import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  CurrentUserContext,
  getCurrentUserFromCookie,
  signInRoutePath,
  signUpRoutePath,
  UserCookie,
} from './auth';
import { useEffect, useState } from 'react';
import {
  MainPage,
  SignUpPage,
  SignInPage,
  NotFoundPage,
  BackOfficePage,
  ForbiddenPage,
  GoogleCallbackPage,
  BackOfficeUserDetailsPage,
} from './pages';
import { LoadingPage, ProtectedElement } from './components';
import { isDemoMode } from './configuration';
import { useSignInAsDemoUser } from './requests/api/auth/sign-in';

export default function App() {
  const [currentUser, setCurrentUser] = useState<UserCookie | null>(
    getCurrentUserFromCookie(),
  );

  const signInAsDemoUser = useSignInAsDemoUser();

  function refreshCurrentUser() {
    const user = getCurrentUserFromCookie();
    setCurrentUser(user);
  }

  useEffect(() => {
    if (!currentUser && isDemoMode()) {
      signInAsDemoUser().then(() => refreshCurrentUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (!currentUser && isDemoMode()) {
    return <LoadingPage />;
  }

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        refreshCurrentUser,
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<ProtectedElement element={<MainPage />} />} />
          <Route
            path="/back-office"
            element={
              <ProtectedElement
                element={<BackOfficePage />}
                permission="back-office:read"
              />
            }
          />
          <Route
            path="/back-office/users/:userId"
            element={
              <ProtectedElement
                element={<BackOfficeUserDetailsPage />}
                permission="back-office:read"
              />
            }
          />
          {!isDemoMode() && <Route path={signUpRoutePath} element={<SignUpPage />} />}
          {!isDemoMode() && <Route path={signInRoutePath} element={<SignInPage />} />}
          <Route path="/google/callback" element={<GoogleCallbackPage />} />
          <Route path="/forbidden" element={<ForbiddenPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </CurrentUserContext.Provider>
  );
}
