import { getWidthPercentage } from './percentage-bar.utils';

export default function PercentageBar({
  values: { value, comparisonValue },
  maxValue,
  isLoading,
}: {
  values: { value: number | null; comparisonValue?: number | null };
  maxValue: number | null;
  isLoading?: boolean;
}) {
  return (
    <div>
      <div
        className={`h-2 rounded-lg ${isLoading ? 'animate-pulse bg-loading' : 'bg-blue-400'}`}
        style={{
          width: `${getWidthPercentage(value, maxValue)}%`,
        }}
      />
      {comparisonValue !== undefined && (
        <div
          className={`mt-1 h-2 rounded-lg ${isLoading ? 'animate-pulse bg-loading' : 'bg-comparison-400'}`}
          style={{
            width: `${getWidthPercentage(comparisonValue, maxValue)}%`,
          }}
        />
      )}
    </div>
  );
}
