import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';
import { LoadingPage } from '../../components';
import { useHandleGoogleSignInRedirection } from '../../requests/api/auth/sign-in';
import { signInRoutePath, signUpRoutePath, useAuth } from '../../auth';
import { GOOGLE_ACTIONS, GOOGLE_ACTION_LABEL } from '../../utils';
import { useHandleGoogleSignUpRedirection } from '../../requests/api/auth/sign-up';
import { useHandleGa4ConnectRedirection } from '../../requests/api/ga4.requests';

export default function GoogleCallbackPage() {
  const { refreshCurrentUser } = useAuth();

  const handleGoogleSignInRedirection = useHandleGoogleSignInRedirection();
  const handleGoogleSignUpRedirection = useHandleGoogleSignUpRedirection();
  const handleGa4ConnectRedirection = useHandleGa4ConnectRedirection();

  const handler = useQuery({
    queryKey: ['googleRedirectionHandler'],
    queryFn: async () => {
      const googleAction = localStorage.getItem(GOOGLE_ACTION_LABEL);

      switch (googleAction) {
        case GOOGLE_ACTIONS.SIGN_IN:
          await handleGoogleSignInRedirection({ searchParams: window.location.search });
          break;
        case GOOGLE_ACTIONS.SIGN_UP:
          await handleGoogleSignUpRedirection({ searchParams: window.location.search });
          break;
        case GOOGLE_ACTIONS.CONNECT_TO_GA4:
          await handleGa4ConnectRedirection({ searchParams: window.location.search });
          break;
        default:
          break;
      }

      refreshCurrentUser();

      // The useQuery throws an error if the queryFn returns undefined.
      return {};
    },
  });

  if (handler.isPending) {
    return <LoadingPage />;
  }

  if (handler.isError) {
    const googleAction = localStorage.getItem(GOOGLE_ACTION_LABEL);

    const errorReason = (handler.error as any)?.response?.data?.reason;

    switch (googleAction) {
      case GOOGLE_ACTIONS.SIGN_IN:
        return (
          <Navigate to={signInRoutePath} state={{ signInFailed: true, errorReason }} />
        );
      case GOOGLE_ACTIONS.SIGN_UP:
        return (
          <Navigate to={signUpRoutePath} state={{ signUpFailed: true, errorReason }} />
        );
      case GOOGLE_ACTIONS.CONNECT_TO_GA4:
        return <Navigate to="/" state={{ connectionToGa4Failed: true }} />;
      default:
        return <LoadingPage hasError />;
    }
  }

  const redirectPath = localStorage.getItem('redirectPath');
  if (redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return <Navigate to="/" />;
}
