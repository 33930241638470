export function formatPercentage(
  n: number,
  options?: { displayPlusSign?: boolean },
): string {
  const numberFormatOptions: Intl.NumberFormatOptions = {
    style: 'percent',
  };

  if (options?.displayPlusSign) {
    numberFormatOptions.signDisplay = 'exceptZero';
  }

  const numberFormatter = new Intl.NumberFormat(
    process.env.REACT_APP_INTL_LANGUAGE,
    numberFormatOptions,
  );

  const formattedNumber = numberFormatter.format(n);

  return formattedNumber;
}
