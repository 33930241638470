import { Website } from '../../../requests/api/website.requests';

export const LAST_SELECTED_WEBSITE_ID_KEY = 'last-selected-website-id';

export function saveLastSelectedWebsiteId(websiteId: string): void {
  localStorage.setItem(LAST_SELECTED_WEBSITE_ID_KEY, websiteId);
}

export function getDefaultWebsiteId(websites: Website[] | undefined): string | undefined {
  if (!websites || websites.length === 0) {
    return undefined;
  }

  const lastSelectedWebsiteId = localStorage.getItem(LAST_SELECTED_WEBSITE_ID_KEY);
  if (lastSelectedWebsiteId && websites.some(w => w.id === lastSelectedWebsiteId)) {
    return lastSelectedWebsiteId;
  }

  return websites[0].id;
}
