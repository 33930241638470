import { Navigate, useLocation } from 'react-router-dom';
import { useAuth, signInRoutePath } from '../auth';

export default function ProtectedElement({
  element,
  permission,
}: {
  element: JSX.Element;
  permission?: string;
}) {
  const { currentUser } = useAuth();
  const { pathname } = useLocation();

  if (!currentUser) {
    localStorage.setItem('redirectPath', pathname);

    // The replace is needed so that the user can go back to the previous page in the browser.
    return <Navigate to={signInRoutePath} replace />;
  }

  if (permission && !currentUser.permissions.includes(permission)) {
    // The replace is needed so that the user can go back to the previous page in the browser.
    return <Navigate to="/forbidden" replace />;
  }

  localStorage.removeItem('redirectPath');

  return element;
}
