export default function MaxItemsPerPageSelect({
  maxItemsPerPage,
  onMaxItemsPerPageChange,
}: {
  maxItemsPerPage: number;
  onMaxItemsPerPageChange: (maxItemsPerPage: number) => void;
}) {
  return (
    <select
      title="Rows per page"
      value={maxItemsPerPage.toString()}
      onChange={value => onMaxItemsPerPageChange(parseInt(value.target.value, 10))}
      className="h-8 w-11 border-none p-0 text-tremor-label text-tremor-content focus:ring-0"
    >
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="50">50</option>
    </select>
  );
}
