import { useMakeAuthenticatedGetRequest } from '../requests.hooks';

export interface Website {
  id: string;
  name: string;
}

export function useGetWebsites(): () => Promise<{
  websites: Website[];
  isUserConnectedToGoogleAnalytics: boolean;
}> {
  const { makeAuthenticatedGetRequest } = useMakeAuthenticatedGetRequest();

  return async () => {
    const { data } = await makeAuthenticatedGetRequest('/api/websites');
    return data;
  };
}
