export function formatFilterKey(key: string): string {
  switch (key) {
    case 'sourceName':
      return 'Source name';
    case 'mediumName':
      return 'Medium name';
    case 'campaignName':
      return 'Campaign name';
    case 'pagePath':
      return 'Page path';
    case 'channelGroup':
      return 'Channel group';
    case 'landingPage':
      return 'Landing page';
    default:
      return key;
  }
}
