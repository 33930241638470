export function formatSecondsInMinutes(seconds: number): string {
  const durationFormatter = new Intl.DateTimeFormat(process.env.REACT_APP_INTL_LANGUAGE, {
    minute: 'numeric',
    second: '2-digit',
  });

  return durationFormatter
    .formatToParts(seconds * 1000)
    .map(part => {
      if (part.type === 'minute') {
        return (part.value.startsWith('0') ? part.value.slice(1) : part.value) + 'min';
      } else if (part.type === 'second') {
        return part.value + 's';
      }
      return null;
    })
    .filter(value => value !== null)
    .join(' ');
}
