import { Card } from '@tremor/react';
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
} from '../../components';
import { CardTitle } from '../main/traffic-section/common';
import { useListAllUsers } from '../../requests/api/back-office.requests';
import { useQuery } from '@tanstack/react-query';
import { formatNumber } from '../../utils';
import { AccountStatus } from './common';
import { Link } from 'react-router-dom';

export default function BackOfficePage() {
  const listAllUsers = useListAllUsers();

  const allUsers = useQuery({
    queryKey: ['allUsers'],
    queryFn: () => listAllUsers(),
  });

  return (
    <div className="container mx-auto space-y-6 px-20 pt-16">
      <h1 className="pb-4 text-3xl">Linito's Back Office</h1>
      <Card>
        <div className="flex items-center">
          <CardTitle title="Users" />
          <div className="grow"></div>
          {allUsers.isSuccess && (
            <div className="flex space-x-4 text-tremor-default">
              <div>
                <span className="font-bold">Total users: </span>
                {formatNumber(allUsers.data?.length)}
              </div>
              <div>
                <span className="font-bold">Total users synchro: </span>
                {formatNumber(
                  allUsers.data?.filter(user => user.status === 'ga4-connected').length,
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell small first>
                  User
                </TableHeaderCell>
                <TableHeaderCell small>Email</TableHeaderCell>
                <TableHeaderCell small>First name</TableHeaderCell>
                <TableHeaderCell small>Last name</TableHeaderCell>
                <TableHeaderCell small>Account status</TableHeaderCell>
                <TableHeaderCell small>User ID</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allUsers.isSuccess &&
                allUsers.data.map(user => (
                  <TableRow key={user.id}>
                    <TableCell size="sm" textAlign="left" bold dark>
                      <Link to={`/back-office/users/${user.id}`}>
                        {user.firstName} {user.lastName}
                      </Link>
                    </TableCell>
                    <TableCell size="sm">{user.email}</TableCell>
                    <TableCell size="sm">{user.firstName}</TableCell>
                    <TableCell size="sm">{user.lastName}</TableCell>
                    <TableCell size="sm">
                      <AccountStatus status={user.status} />
                    </TableCell>
                    <TableCell size="sm">{user.id}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  );
}
