import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { getPaginationButtons } from './pagination-bar.utils';

function LeftButton({ invisible, onClick }: { invisible: boolean; onClick: () => void }) {
  return (
    <button
      className={`${invisible ? 'invisible' : ''} mt-2 inline-flex items-center border-b-2 border-transparent px-3 pb-2 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700`}
      onClick={() => onClick()}
    >
      <RiArrowLeftSLine className="h-5 w-5" />
    </button>
  );
}

function RightButton({
  invisible,
  onClick,
}: {
  invisible: boolean;
  onClick: () => void;
}) {
  return (
    <button
      className={`${invisible ? 'invisible' : ''} mt-2 inline-flex items-center border-b-2 border-transparent px-3 pb-2 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700`}
      onClick={() => onClick()}
    >
      <RiArrowRightSLine className="h-5 w-5" />
    </button>
  );
}

function PageButton({
  number,
  onClick,
  isSelected,
}: {
  number: number;
  onClick: () => void;
  isSelected?: boolean;
}) {
  if (isSelected) {
    return (
      <button
        className="mt-2 inline-flex items-center border-b-2 border-indigo-500 px-3 pb-2 text-sm font-medium text-indigo-600"
        aria-current="page"
      >
        {number}
      </button>
    );
  }

  return (
    <button
      className="mt-2 inline-flex items-center border-b-2 border-transparent px-3 pb-2 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
      onClick={() => onClick()}
    >
      {number}
    </button>
  );
}

function ThreeDotsFakeButton() {
  return (
    <button
      className="mt-2 inline-flex items-center border-b-2 border-transparent px-3 pb-2 text-sm font-medium text-gray-500"
      disabled
    >
      ...
    </button>
  );
}

export default function PaginationBar({
  numberOfPages,
  currentPageIndex,
  onCurrentPageIndexChange,
}: {
  numberOfPages: number;
  currentPageIndex: number;
  onCurrentPageIndexChange: (pageNumber: number) => void;
}) {
  const buttons = getPaginationButtons({
    numberOfPages,
    currentPageNumber: currentPageIndex + 1,
  });

  return (
    <nav className="flex flex-wrap items-center justify-center border-gray-200 px-4 sm:px-0">
      <LeftButton
        onClick={() => onCurrentPageIndexChange(currentPageIndex - 1)}
        invisible={currentPageIndex === 0}
      />

      {buttons.map((button, index) => {
        if (button.type === 'button') {
          return (
            <PageButton
              key={index}
              number={button.pageNumber}
              isSelected={button.pageNumber === currentPageIndex + 1}
              onClick={() => onCurrentPageIndexChange(button.pageNumber - 1)}
            />
          );
        }
        return <ThreeDotsFakeButton key={index} />;
      })}

      <RightButton
        onClick={() => onCurrentPageIndexChange(currentPageIndex + 1)}
        invisible={currentPageIndex === numberOfPages - 1}
      />
    </nav>
  );
}
