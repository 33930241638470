import { PropsWithChildren } from 'react';

export default function TableHeaderCell({
  children,
  small,
  first,
}: PropsWithChildren & { small?: boolean; first?: boolean }) {
  return (
    <th
      scope="col"
      className={`whitespace-nowrap ${small ? 'px-2 py-3.5' : 'px-3 py-3.5'} ${first ? 'text-left' : 'text-center'} text-sm font-semibold text-gray-900`}
    >
      {children}
    </th>
  );
}
