import { Select, SelectItem } from '@tremor/react';
import { ExtendedDatePicker, SecondaryButton } from '../../../../components';
import {
  Comparison,
  DateRange,
  Filter,
  useGetFilterDefinitions,
} from '../../../../requests/api/traffic.requests';
import { useState } from 'react';
import { FilterTags } from './filter-tags';
import { AddFilterForm } from './add-filter-form';
import { useQuery } from '@tanstack/react-query';
import { Website } from '../../../../requests/api/website.requests';
import { RiCloseLine, RiContrastFill, RiFilterLine } from '@remixicon/react';

export default function TrafficSectionNavbar({
  websites,
  websiteId,
  dateRange,
  filters,
  comparison,
  onWebsiteIdChange,
  onDateRangeChange,
  onFiltersChange,
  onComparisonChange,
}: {
  websites: Website[];
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
  onWebsiteIdChange: (websiteId: string) => void;
  onDateRangeChange: (dateRange: DateRange) => void;
  onFiltersChange: (filters: Filter[]) => void;
  onComparisonChange: (
    comparison: { dateRange: DateRange; filters: Filter[] } | undefined,
  ) => void;
}) {
  const [isAddFilterFormVisible, setIsAddFilterFormVisible] = useState(false);

  const getFilterDefinitions = useGetFilterDefinitions();
  const filterDefinitions = useQuery({
    queryKey: ['filterDefinitions'],
    queryFn: () => getFilterDefinitions(),
  });

  const enableComparison = () => {
    onComparisonChange({
      dateRange: {
        type: 'shortcut',
        name: 'previousPeriod',
      },
      filters,
    });
  };

  const disableComparison = () => {
    onComparisonChange(undefined);
  };

  const addFilter = (filter: Filter) => {
    const newFilters = [...filters, filter];
    onFiltersChange(newFilters);
    setIsAddFilterFormVisible(false);
  };

  const onFilterRemoved = (filter: Filter) => {
    const newFilters = filters.filter(f => f !== filter);
    onFiltersChange(newFilters);
  };

  const addComparisonFilter = (filter: Filter) => {
    if (!comparison) {
      return;
    }

    onComparisonChange({
      ...comparison,
      filters: [...comparison.filters, filter],
    });

    setIsAddFilterFormVisible(false);
  };

  const removeComparisonFilter = (filter: Filter) => {
    if (!comparison) {
      return;
    }

    onComparisonChange({
      ...comparison,
      filters: comparison.filters.filter(f => f !== filter),
    });
  };

  const setComparisonDateRange = (dateRange: DateRange) => {
    if (!comparison) {
      return;
    }

    onComparisonChange({
      ...comparison,
      dateRange,
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col items-center gap-4 lg:flex-row">
        <Select
          className="w-full lg:w-80"
          value={websiteId}
          onValueChange={onWebsiteIdChange}
        >
          {websites.map(website => (
            <SelectItem key={website.id} value={website.id}>
              {website.name}
            </SelectItem>
          ))}
        </Select>
        <div className="hidden grow lg:block" />
        <div className="flex flex-wrap items-center gap-4">
          {!comparison && (
            <SecondaryButton className="h-9" onClick={enableComparison}>
              <span className="flex items-center space-x-1 text-tremor-default">
                <RiContrastFill className="h-4 w-4" />
                <span>Compare</span>
              </span>
            </SecondaryButton>
          )}
          <SecondaryButton
            className="h-9"
            onClick={() => setIsAddFilterFormVisible(!isAddFilterFormVisible)}
          >
            <span className="flex items-center space-x-1 text-tremor-default">
              <RiFilterLine className="h-4 w-4" />
              <span>Filter</span>
            </span>
          </SecondaryButton>
          <span className="flex flex-wrap items-center space-x-2">
            <ExtendedDatePicker
              value={dateRange}
              onValueChange={onDateRangeChange}
              className={comparison ? 'select-blue' : ''}
            />
            {comparison && <span>vs</span>}
            {comparison && (
              <ExtendedDatePicker
                className="select-comparison"
                value={comparison.dateRange}
                onValueChange={setComparisonDateRange}
                isComparisonDatePicker
              />
            )}
          </span>
          {comparison && (
            <SecondaryButton className="h-9" onClick={disableComparison}>
              <RiCloseLine className="h-4 w-4" />
            </SecondaryButton>
          )}
        </div>
      </div>
      {isAddFilterFormVisible && (
        <AddFilterForm
          filterDefinitions={filterDefinitions.data || []}
          className="justify-end"
          onCancel={() => setIsAddFilterFormVisible(false)}
          onAddFilter={addFilter}
          onAddComparisonFilter={comparison && addComparisonFilter}
        />
      )}
      {filters.length > 0 && (
        <FilterTags
          className="justify-end"
          filters={filters}
          onFilterRemoved={onFilterRemoved}
        />
      )}
      {comparison && comparison.filters.length > 0 && (
        <FilterTags
          className="justify-end"
          color="amber"
          filters={comparison.filters}
          onFilterRemoved={removeComparisonFilter}
        />
      )}
    </div>
  );
}
