import { Card, DonutChart, Legend, Callout, List, ListItem } from '@tremor/react';
import { CardTitle, formatPageViewsPerVisit, useBlock } from '../common';
import {
  formatNumber,
  formatPercentage,
  formatSecondsInMinutes,
} from '../../../../utils';
import { Comparison, DateRange, Filter } from '../../../../requests/api/traffic.requests';
import { NumberValue, SkeletonLoader } from '../../../../components';

interface KeyMetricsCardProps {
  websiteId: string;
  dateRange: DateRange;
  filters: Filter[];
  comparison: Comparison | undefined;
  onInitialLoadingComplete: () => void;
}

export default function KeyMetricsCard({
  websiteId,
  dateRange,
  filters,
  comparison,
  onInitialLoadingComplete,
}: KeyMetricsCardProps) {
  const block = useBlock({
    blockName: 'keyMetrics',
    websiteId,
    dateRange,
    filters,
    comparison,
    queryKey: 'keyMetricsBlock',
    onInitialLoadingComplete,
  });

  return (
    <Card>
      <CardTitle title="Key metrics" />
      {block.isPending && <SkeletonLoader className="mt-4 h-56" />}
      {block.isError && (
        <Callout title="An error has occurred" color="red" className="mt-4">
          An error occurred while retrieving the data. Try refreshing the page.
        </Callout>
      )}
      {block.isSuccess && (
        <>
          <List className="mt-4">
            <ListItem>
              <span>Total visits</span>
              <NumberValue
                className="text-right"
                values={block.data.totalVisits}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </ListItem>
            <ListItem>
              <span>Total pageviews</span>
              <NumberValue
                className="text-right"
                values={block.data.totalPageViews}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </ListItem>
            <ListItem>
              <span>Pageviews per visit</span>
              <NumberValue
                className="text-right"
                values={block.data.averagePageViewsPerVisit}
                valueFormatter={formatPageViewsPerVisit}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </ListItem>
            <ListItem>
              <span>Engagement rate</span>
              <NumberValue
                className="text-right"
                values={block.data.engagementRate}
                valueFormatter={formatPercentage}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </ListItem>
            <ListItem>
              <span>Visit duration</span>
              <NumberValue
                className="text-right"
                values={block.data.averageVisitDuration}
                valueFormatter={formatSecondsInMinutes}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </ListItem>
            <ListItem>
              <span>Total visitors</span>
              <NumberValue
                className="text-right"
                values={block.data.totalVisitors}
                isLoading={block.isRefetching}
                showComparisonBadgeUponComparison
              />
            </ListItem>
          </List>
          {block.isSuccess && (
            <>
              <div className="flex justify-center space-x-6">
                <div className="w-24">
                  <DonutChart
                    data={[
                      { name: 'New visitors', value: block.data.totalNewVisitors.value },
                      {
                        name: 'Other visitors',
                        value: block.data.totalOtherVisitors.value,
                      },
                    ]}
                    category="value"
                    variant="pie"
                    index="name"
                    colors={block.isRefetching ? ['loading', 'loading'] : undefined}
                    valueFormatter={formatNumber}
                  />
                </div>
                {block.data.totalNewVisitors.comparisonValue !== undefined && (
                  <div className="w-24">
                    <DonutChart
                      data={[
                        {
                          name: 'New visitors',
                          value: block.data.totalNewVisitors.comparisonValue,
                        },
                        {
                          name: 'Other visitors',
                          value: block.data.totalOtherVisitors.comparisonValue,
                        },
                      ]}
                      category="value"
                      variant="pie"
                      index="name"
                      colors={block.isRefetching ? ['loading', 'loading'] : undefined}
                      valueFormatter={formatNumber}
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-center">
                <Legend
                  categories={['New visitors', 'Other visitors']}
                  colors={block.isRefetching ? ['loading', 'loading'] : undefined}
                />
              </div>
            </>
          )}
        </>
      )}
    </Card>
  );
}
