export function getPaginationButtons({
  numberOfPages,
  currentPageNumber,
}: {
  numberOfPages: number;
  currentPageNumber: number;
}): (
  | {
      type: 'button';
      pageNumber: number;
    }
  | { type: 'threeDots' }
)[] {
  if (numberOfPages <= 7) {
    return Array.from({ length: numberOfPages }, (_, i) => ({
      type: 'button',
      pageNumber: i + 1,
    }));
  }

  const pages: ReturnType<typeof getPaginationButtons> = [];

  pages.push({ type: 'button', pageNumber: 1 });

  if (currentPageNumber <= 4) {
    pages.push({ type: 'button', pageNumber: 2 });
    pages.push({ type: 'button', pageNumber: 3 });
    pages.push({ type: 'button', pageNumber: 4 });
    pages.push({ type: 'button', pageNumber: 5 });
    pages.push({ type: 'threeDots' });
  } else if (currentPageNumber >= numberOfPages - 3) {
    pages.push({ type: 'threeDots' });
    pages.push({ type: 'button', pageNumber: numberOfPages - 4 });
    pages.push({ type: 'button', pageNumber: numberOfPages - 3 });
    pages.push({ type: 'button', pageNumber: numberOfPages - 2 });
    pages.push({ type: 'button', pageNumber: numberOfPages - 1 });
  } else {
    pages.push({ type: 'threeDots' });
    pages.push({ type: 'button', pageNumber: currentPageNumber - 1 });
    pages.push({ type: 'button', pageNumber: currentPageNumber });
    pages.push({ type: 'button', pageNumber: currentPageNumber + 1 });
    pages.push({ type: 'threeDots' });
  }

  pages.push({ type: 'button', pageNumber: numberOfPages });

  return pages;
}
