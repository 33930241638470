import { Badge } from '@tremor/react';
import { formatPercentage } from '../utils';

export default function ComparisonBadge({
  values: { value, comparisonValue },
  isLoading,
}: {
  values: {
    value: number | null;
    comparisonValue?: number | null;
  };
  isLoading?: boolean;
}) {
  if (comparisonValue === undefined) {
    return <></>;
  }

  const variation = ((value ?? 0) - (comparisonValue ?? 0)) / (comparisonValue ?? 0);
  const formattedVariation = formatPercentage(variation, { displayPlusSign: true });

  if (isLoading) {
    return (
      <Badge size="xs" className="animate-pulse bg-loading ring-0">
        <span className="invisible">{formattedVariation}</span>
      </Badge>
    );
  }

  return (
    <Badge size="xs" color={variation > 0 ? 'green' : variation === 0 ? 'gray' : 'red'}>
      {formattedVariation}
    </Badge>
  );
}
