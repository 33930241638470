export type DateFormat = 'day-and-month' | 'hours-and-minutes' | 'month-and-year';

export function formatDateTime({
  dateTime,
  format,
}: {
  dateTime: string;
  format: DateFormat;
}): string {
  const options: Record<DateFormat, Intl.DateTimeFormatOptions> = {
    'day-and-month': {
      day: 'numeric',
      month: 'short',
    },
    'hours-and-minutes': {
      minute: '2-digit',
      hour: '2-digit',
    },
    'month-and-year': {
      month: 'long',
      year: 'numeric',
    },
  };

  const dateFormatter = new Intl.DateTimeFormat(
    process.env.REACT_APP_INTL_LANGUAGE,
    options[format],
  );
  return dateFormatter.format(new Date(dateTime));
}
