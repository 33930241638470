import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  BlockName,
  Comparison,
  DateRange,
  Filter,
  useGetTrafficBlock,
} from '../../../../requests/api/traffic.requests';
import { getNowDate } from '../../../../utils';

export function useBlock<T extends BlockName>({
  websiteId,
  queryKey,
  blockName,
  dateRange,
  filters,
  comparison,
  onInitialLoadingComplete,
}: {
  websiteId: string;
  queryKey: string;
  blockName: T;
  dateRange: DateRange;
  filters: Filter[];
  comparison?: Comparison;
  onInitialLoadingComplete?: () => void;
}) {
  const getTrafficBlock = useGetTrafficBlock();
  const block = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      const currentDate = getNowDate();

      return getTrafficBlock({
        websiteId,
        blockName,
        currentDate,
        dateRange,
        filters,
        comparison,
      });
    },
  });

  useEffect(() => {
    block.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websiteId, blockName, dateRange, filters, comparison]);

  useEffect(() => {
    if (!block.isLoading || block.isLoadingError) {
      onInitialLoadingComplete && onInitialLoadingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block.isLoading, block.isLoadingError]);

  return block;
}
