import { PropsWithChildren } from 'react';

export default function LightButton({
  children,
  onClick,
  className,
}: PropsWithChildren & { onClick: () => void; className?: string }) {
  return (
    <button
      className={`${className ?? ''} rounded-md  px-2.5 py-1.5 text-sm text-gray-700 ring-0  ring-gray-300 hover:bg-gray-50`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
