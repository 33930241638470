import { TabGroup, TabList, Tab } from '@tremor/react';

export default function Navbar() {
  return (
    <nav>
      <TabGroup>
        <TabList variant="line" defaultValue="1">
          <Tab value="1">Traffic</Tab>
          {/* <Tab value="2">Ranking</Tab>
          <Tab value="2">Conversion</Tab>
          <Tab value="3">Custom reports</Tab>
          <Tab value="3">Configuration</Tab> */}
        </TabList>
      </TabGroup>
    </nav>
  );
}
