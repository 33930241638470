import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import Spinner from './Spinner';

export default function LoadingPage({ hasError }: { hasError?: boolean }) {
  return (
    <div className="flex min-h-full items-center justify-center">
      {hasError ? (
        <div className="flex flex-col gap-y-3 rounded-lg bg-white px-9 py-7 text-gray-700 shadow">
          <div className="mx-auto flex h-12 w-12 items-center justify-center">
            <ExclamationTriangleIcon
              className="h-10 w-10 text-gray-600"
              aria-hidden="true"
            />
          </div>
          An error has occurred. Unable to load page.
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
