export function formatFilterMatchKey(key: string): string {
  switch (key) {
    case 'equals':
      return 'equals';
    case 'doesNotEqual':
      return 'does not equal';
    case 'contains':
      return 'contains';
    case 'doesNotContain':
      return 'does not contain';
    default:
      return key;
  }
}
