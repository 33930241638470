import { createContext, useContext } from 'react';
import * as cookie from 'cookie';

export interface UserCookie {
  id: number;
  permissions: string[];
}

export const signInRoutePath = '/sign-in';
export const signUpRoutePath = '/sign-up';

export function getCurrentUserFromCookie(): UserCookie | null {
  const userCookie = cookie.parse(document.cookie).user as string | undefined;
  if (!userCookie) {
    return null;
  }

  try {
    return JSON.parse(userCookie);
  } catch (error: any) {
    return null;
  }
}

export const CurrentUserContext = createContext<{
  currentUser: UserCookie | null;
  refreshCurrentUser: () => void;
}>({
  currentUser: null,
  refreshCurrentUser: () => {},
});

export const useAuth = () => useContext(CurrentUserContext);
