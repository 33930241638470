import { useState, useEffect } from 'react';

export function getNumberOfPages({
  items,
  maxItemsPerPage,
}: {
  items: unknown[];
  maxItemsPerPage: number;
}): number {
  return Math.ceil(items.length / maxItemsPerPage);
}

export function getPaginatedItems<T>({
  items,
  currentPageIndex,
  maxItemsPerPage,
}: {
  items: T[];
  currentPageIndex: number;
  maxItemsPerPage: number;
}): T[] {
  return items.slice(
    currentPageIndex * maxItemsPerPage,
    (currentPageIndex + 1) * maxItemsPerPage,
  );
}

export function usePagination<T>({
  items,
  maxItemsPerPage = 10,
}: {
  items: T[] | undefined;
  maxItemsPerPage?: number;
}): {
  paginatedItems: T[];
  currentPageIndex: number;
  setCurrentPageIndex: (index: number) => void;
  numberOfPages: number;
} {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [items?.length, maxItemsPerPage]);

  if (!items) {
    return {
      paginatedItems: [],
      currentPageIndex,
      setCurrentPageIndex,
      numberOfPages: 0,
    };
  }

  const paginatedItems = getPaginatedItems({
    items,
    currentPageIndex,
    maxItemsPerPage,
  });

  const numberOfPages = getNumberOfPages({
    items,
    maxItemsPerPage,
  });

  return {
    paginatedItems,
    currentPageIndex,
    setCurrentPageIndex,
    numberOfPages,
  };
}
