import axios from 'axios';

import { useAuth } from '../auth';

function wrapAsyncFunctionWithErrorHandling<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  {
    onAuthenticationError,
  }: {
    onAuthenticationError: () => void;
  },
): T {
  return async function (...args: Parameters<T>): Promise<ReturnType<T>> {
    try {
      const result = await fn(...args);
      return result;
    } catch (error: any) {
      if (error.response?.status === 401) {
        onAuthenticationError();
      }
      throw error;
    }
  } as T;
}

export function useMakeGetRequest() {
  const makeGetRequest = axios.get;

  return { makeGetRequest };
}

export function useMakeAuthenticatedGetRequest() {
  const { refreshCurrentUser } = useAuth();

  const makeAuthenticatedGetRequest = wrapAsyncFunctionWithErrorHandling(axios.get, {
    onAuthenticationError: refreshCurrentUser,
  });

  return { makeAuthenticatedGetRequest };
}

export function useMakePostRequest() {
  const makePostRequest = axios.post;

  return { makePostRequest };
}

export function useMakeAuthenticatedPostRequest() {
  const { refreshCurrentUser } = useAuth();

  const makeAuthenticatedPostRequest = wrapAsyncFunctionWithErrorHandling(axios.post, {
    onAuthenticationError: refreshCurrentUser,
  });

  return { makeAuthenticatedPostRequest };
}
