import { PagesBlockName } from './PagesCard';

export function getFilterKeyFromBlockName(blockName: PagesBlockName): string {
  switch (blockName) {
    case 'pages':
      return 'pagePath';
    case 'landingPages':
      return 'landingPage';
    default:
      throw new Error('Invalid block name');
  }
}
