import { useState, useEffect } from 'react';

export function getPaginatedItems<T>({
  items,
  currentPageIndex,
  maxItemsPerPage,
}: {
  items: T[];
  currentPageIndex: number;
  maxItemsPerPage: number;
}): T[] {
  return items.slice(0, (currentPageIndex + 1) * maxItemsPerPage);
}

export function useShowMorePagination<T>({
  items,
  maxItemsPerPage = 10,
}: {
  items: T[] | undefined;
  maxItemsPerPage?: number;
}): {
  paginatedItems: T[];
  showMoreItems: () => void;
  canMoreItemsBeShown: boolean;
} {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [items?.length]);

  if (!items) {
    return {
      paginatedItems: [],
      showMoreItems: () => {},
      canMoreItemsBeShown: false,
    };
  }

  const paginatedItems = getPaginatedItems({
    items,
    currentPageIndex,
    maxItemsPerPage,
  });

  return {
    paginatedItems,
    showMoreItems: () => setCurrentPageIndex(currentPageIndex + 1),
    canMoreItemsBeShown: paginatedItems.length < items.length,
  };
}
