import { Button } from '@tremor/react';
import logo from './logo_with_title_298x96.png';
import { useSignOut } from '../../../requests/api/auth/sign-out';
import { useAuth } from '../../../auth';
import { isDemoMode } from '../../../configuration';

export default function Header() {
  const { refreshCurrentUser } = useAuth();
  const signOut = useSignOut();

  const logOut = async () => {
    await signOut();

    refreshCurrentUser();
  };

  return (
    <header className="flex ">
      <img className="h-10" src={logo} alt="Linito" />
      <div className="grow"></div>
      <div className="mr-2 flex items-center">
        {isDemoMode() ? (
          <a
            href="https://app.linito.io/sign-up"
            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Sign up
          </a>
        ) : (
          <Button variant="light" onClick={logOut}>
            Log out
          </Button>
        )}
      </div>
    </header>
  );
}
