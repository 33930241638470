import { Card, Button, Callout } from '@tremor/react';
import { useGetGoogleSignInURL } from '../../requests/api/auth/sign-in';
import { useLocation } from 'react-router-dom';
import { GOOGLE_ACTIONS, GOOGLE_ACTION_LABEL } from '../../utils';
import { RiGoogleFill } from '@remixicon/react';

export default function SignInPage() {
  const { state } = useLocation();
  const getGoogleSignInURL = useGetGoogleSignInURL();

  async function signInWithGoogle() {
    try {
      const googleSignInURL = await getGoogleSignInURL();
      localStorage.setItem(GOOGLE_ACTION_LABEL, GOOGLE_ACTIONS.SIGN_IN);
      window.location.href = googleSignInURL;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-4 py-10 lg:px-6">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Card>
          <div className="space-y-6 p-2">
            <h3 className="text-center text-tremor-title font-semibold text-tremor-content-strong">
              Log in to your account
            </h3>
            <p className="text-center text-tremor-label text-tremor-content">
              Welcome back!
            </p>
            {state?.signInFailed && (
              <Callout title="An error has occurred" color="red">
                {state.errorReason === 'no_user_associated_with_google_account'
                  ? 'No user associated with this Google account was found. Try signing up instead.'
                  : 'Impossible to log in with this account.'}
              </Callout>
            )}
            <Button className="w-full" icon={RiGoogleFill} onClick={signInWithGoogle}>
              Sign in with Google
            </Button>
          </div>
        </Card>
        <p className="mt-4 text-center text-tremor-default text-tremor-content ">
          Don't have an account?{' '}
          <a
            href="/sign-up"
            className="text-tremor-default font-medium text-tremor-brand hover:text-tremor-brand-emphasis"
          >
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
}
